@font-face {
	font-family: "BigCaslon";
  font-weight: normal;
	src: url('fonts/BigCaslon.ttf') format('truetype');
}

@font-face {
  font-family: "FrutigerLTStd-Light";
  src: url("fonts/FrutigerLTStd-Light.otf") format("opentype");
} 


.uppercase {
  text-transform: uppercase;
}

.flex_textarea{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_textarea div:last-child{
  padding-right: 10px;
}

#master_layer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  opacity: 0.3;
  display: none;
}

.entry_content_hover{
  width: 300px;
  margin-left: 1vw;
  z-index: 1;
}

.entry_content_hover h1 {
  text-transform: uppercase;
  font-weight: 100;
  margin: 0;
  font-family: "FrutigerLTStd-Light";
}

/* general styling */
body {
  background-color: #e9eaee;
}

.curvedpaths{
  position: fixed;
  z-index: -1;
}

#curvedpath_1{
  top: 0;
  left: 0;
  width: 42vw;
}

.curvedpaths text{
  font-size: 4px;
  font-family: 'BigCaslon';
}


.entry_text {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entry_text img {
  position: absolute;
  width: 180px;
  right: 18vw;
  z-index: -1;
}

.entry_text_cta_section {
  margin-top: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}


.base_entry_author{
  padding-top: 0.5vw;
}

.entry_text_cta_section span {
  background-color: #434343;
  color: #e9eaee;
  padding: 0.7vw;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: BigCaslon;
  cursor: pointer;
  width: 63%;
  text-align: center;
}

.entry_text section {
  width: 64vw;
  display: flex;
  border: 1px solid #434343;
  padding: 2vw;
  border-radius: 20px;
  flex-direction: column;
}

.entry_text_desc,
.entry_text_ctas {
  display: flex;
}

.entry_text section .entry_text_desc div {
  width: 50%;
  margin: 4vw;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entry_text section .entry_text_desc div p {
  font-family: FrutigerLTStd-Light;
  line-height: 22px;
  color: #434343;
}


.en_fr_cta_map {
  position: fixed;
  right: 4vw;
  top: 3.5vw;
  z-index: 10;
}

.en_fr_cta_map span {
  text-transform: uppercase;
  font-family: FrutigerLTStd-Light;
  padding: 0.5vw;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
}

.en_fr_cta_map span:first-child {
  border-right: solid;
}

.en_fr_cta_entry {
  position: fixed;
  bottom: 7.5vh;
}

.en_fr_cta_entry span {
  border: 1px solid #434343;
  border-radius: 10px;
  padding: 0.5vw;
  font-family: BigCaslon;
  cursor: pointer;
}

.en_fr_cta_entry span:first-child {
  margin-right: 4vw;
}

.en_fr_cta_entry span:last-child {
  margin-left: 4vw;
}

.non_active_cta {
  color: gray;
  cursor: text;
}

.active_keywords {
}

/* index section */
.index_section,
.ballado_section {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.ballado_main {
  width: 78vw;
  height: 70vh;
  background-color: #e9eaee;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  padding: 2vw;
}

.ballado_main_inner {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.ballado_left {
  border-right: 1px solid;
  width: 34%;
  flex-direction: column;
  justify-content: inherit;
}

.ballado_right{
  width: 66%;
}

.ballado_left h1 {
  font-family: BigCaslon;
  font-weight: 100;
  margin: 0;
  color: #434343;
}

.ballado_left h2 {
  margin: 0;
  color: #434343;
  font-family: FrutigerLTStd-Light;
  font-size: 14px;
  font-weight: 600;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.ballado_left p {
  margin: 0;
  color: #434343;
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  font-weight: 100;
}

.ballado_left{
  overflow: scroll;
}

.b_l_1 div img{
  width: 80%;
}

.b_l_2{
  padding-top: 1vw;
}

.b_l_1,
.b_l_2{
  padding-right: 1vw;
}

.b_l_2 p:last-child{
  margin-top: 1vw;
}

.ballado_right .b_r_1 {
  width: 50%;
  height: 100%;
  border-right: 1px solid;
  overflow: scroll;
}

.ballado_right .b_r_1 p {
  margin: 0;
  margin-left: 2vw;
  margin-right: 2vw;
  color: #434343;
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  font-weight: 100;
}

.ballado_description_container {
  border-right: 1px solid;
}

.entry_acceptance_main{
  height: 100vh;
  z-index: 100;
  position: fixed;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(233, 234, 238);
  position: fixed;
  left: 0;
  top: 0;
}

.entry_acceptance_popup{
  width: 40vw;
}


.entry_acceptance_popup p{
  border: 1px solid #434343;
  padding: 2vw;
  border-radius: 12px;
  background-color: white;
  font-family: FrutigerLTStd-Light;
  line-height: 22px;
}

.ballado_img_block{
  display: flex;
  flex-direction: column;
}

.ballado_img_block label{
  margin-top: 0.3vw;
  margin-bottom: 0.3vw;
  font-size: 10px;
  font-family: FrutigerLTStd-Light;
}

.entry_acceptance_popup p a{
  color: black;
}

.user_agreement_container{
  left: 2.7vw;
  position: fixed;
  bottom: 2vw;
}


.user_agreement_cta{
  font-family: 'FrutigerLTStd-Light';
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.user_agreement_cta span a{
  color: #434343;
  text-decoration: none;
}


.entry_acceptance_popup span{
  font-family: 'BigCaslon';
  padding: 0.5vw;
  color: white;
  border-radius: 12px;
  background-color: #434343;
  cursor: pointer;
  text-transform: uppercase;
}

.ballado_right .b_r_2 {
  width: 50%;
  overflow: scroll;
  height: 100%;
  margin-top: 1vw;
}

.keyword_container{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.podcast {
  display: flex;
  margin-left: 2vw;
  cursor: pointer;
  flex-direction: column;
  height: 8vh;
  padding: 3vw;
  padding-left: 0;
}

.podcast:first-child{
  margin-top: 0;
  padding-top: 0;
}

.podcast_main {
  align-items: center;
  display: flex;
}

.audio_input_name_title {
  font-family: BigCaslon;
  font-size: 13px;

}



input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  position: relative;
  padding-top: 1vw;
  padding-bottom: 1vw;
  background-color: transparent;
  cursor: pointer;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:before {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  background: #434343;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #434343;
  height: 2px;
}
input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
  -webkit-appearance: none;
  background-color: #e9eaee;
  border: 1px solid;
  margin-top: -3.5px;
  z-index: 99;
  position: relative;
  transition: 0.5s all ease;
}

.audio_input_duration {
  font-size: 12px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #434343;
  font-family: FrutigerLTStd-Light;
  font-weight: 600;
}

#value {
  position: absolute;
  left: 0%;
  top: 41%;
  font-size: 14px;
  color: red;
  transition: 0.5s color ease;
}

.podcast_info {
  font-family: BigCaslon;
}

.audio_first_layer {
  display: flex;
  align-items: center;
}

.podcast_audio_input {
  width: 100%;
}

.audio_button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 24.6px;
  border-color: transparent transparent transparent #434343;
  /* transition: 100ms all ease; */
  cursor: pointer;
  border-style: solid;
  border-width: 12.3px 0 12.3px 20px;
}
.audio_button.paused {
  border-style: double;
  border-width: 0px 0 0px 20px;
}
.audio_button:hover {
  border-color: transparent transparent transparent #434343;
}

.index_section .index_section_main {
  overflow: scroll;
  position: relative;
  width: 98vw;
  height: 96vh;
  border: 1px solid #434343;
  border-radius: 10px;
  padding-top: 0;
}

.index_section_main header span {
  cursor: pointer;
  font-family: BigCaslon;
}

.index_close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1vw;
  cursor: pointer;
}

.index_section .index_section_main header {
  padding-top: 2vh;
  border-bottom: 1px solid #434343;
  padding-bottom: 2vh;
  display: flex;
  margin-bottom: 1.5vh;
  position: fixed;
  width: 98%;
  background-color: #e9eaee;
  border-radius: 10px;
}

.list_index_main {
  margin-top: 4vw;
}

.index_section .index_section_main header span,
.index_section_list_cells div {
  width: 19%;
  display: flex;
  align-items: baseline;
  margin-left: 1vw;
}

.index_section_list_cells_open {
  display: flex;
  align-items: flex-start;
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  display: none;
}

.index_section_open_content {
  margin-top: 1vw;
}

.index_section_list_cells_open_inner_1{
  padding: 2vw;
  width: 49%;
  padding-right: 0;
  white-space: pre-wrap;
}

.index_section_list_cells_open_inner_2{
  padding: 2vw;
  padding-left: 1vw;
  width: 15%;
}


.index_section_list_cells_open_inner_2 img{
  mix-blend-mode: screen;
  filter: grayscale(100%) contrast(100%);
  opacity: 1;
  display: block;
  max-width: 100%;
  border-radius: 5px;
}

.index_section_list_cells {
  display: flex;
  margin-top: 0.25vw;
  margin-bottom: 1.25vw;
  cursor: pointer;
  font-size: 14px;
  font-family: FrutigerLTStd-Light;
}

/* filters section */
.filters_background {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.new_entry_popup_outer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.new_entry_popup_background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  cursor: pointer;
}

.entry_form_popup_cta{
  margin-top: 3vw;
}

.entry_form_popup_cta span{
  border: 1px solid;
  border-radius: 20px;
  padding: 1vw;
  cursor: pointer;
}

.entry_form_popup_cta span:first-child{
  margin-right: 2vw;
  background-color: #434343;
  color: #e9eaee;
}

.entry_form_popup_cta span:last-child{
  margin-left: 2vw;
}

.new_entry_popup_div {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 76vw;
  height: 40vh;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #434343;
  background-color: #e9eaee;
  flex-direction: column;
}

.new_entry_popup_text {
  font-family: BigCaslon;
  font-size: 2vw;
  color: #434343;
  width: 90%;
}

.random_fragment_close {
  left: 2vw;
}

.new_entry_popup_cta {
  position: absolute;
  bottom: 10px;
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  color: #434343;
  cursor: pointer;
}

.contact_background,
.entry_form_background {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: -1;
  cursor: pointer;
}

.entry_form_subheader {
  margin-top: 2vw;
}

.entry_form_delete_cta {
  margin-top: 1vw;
}

.entry_form_delete_file {
  cursor: pointer;
  font-weight: 800;
}

.entry_form_subheader input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #434343;
  margin-bottom: 0.5vw;
}

.entry_form_audio span{
  font-weight: 900;
}

.entry_form_audio,
.entry_form_img {
  margin-top: 2vw;
}

.entry_form_uploads {
  margin-top: 1vw;
}

.entry_form_uploads input {
  margin-bottom: 0.5vw;
  font-family: FrutigerLTStd-Light;
  background-color: transparent;
}

.entry_form_subheader input:focus {
  outline: none;
}

.entry_form header {
  display: flex;
  align-items: baseline;
}



.entry_form header .entry_header_first{
  margin-right: 1vw;
}

.entry_footer_first{
  width: 40%;
}

.entry_footer_second{
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.entry_footer_third {
  width: 27%;
}

.entry_footer_second *{
  width: 69%;
}


.entry_header_first p{
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  text-transform: uppercase;
  color: #434343;
}

.entry_form header .entry_header_second{
  width: 60%;
}


.entry_form header h1 {
  font-family: BigCaslon;
  font-weight: 100;
  font-size: 22px;
  margin: 0;
  color: #434343;
  text-transform: uppercase;
  margin-bottom: 3.5vw;
}

.entry_form header span{
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  text-transform: uppercase;
}

.entry_form {
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  z-index: 2;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entry_form section {
  width: 72vw;
  /* height: 75vh; */
  border: 1px solid #434343;
  background-color: #e9eaee;
  border-radius: 10px;
  padding: 2vw;
  padding-top: 0;
  padding-bottom: 1vw;
}

.entry_form_cta_outer {
  font-family: BigCaslon;
  text-align: center;
  width: 26%;
}

.entry_form_generate_question {
  border: 1px solid;
  padding: 0.5vw;
  border-radius: 8px;
  color: #434343;
  cursor: pointer;
  font-family: BigCaslon;
  text-align: center;
  text-transform: uppercase;
}

.entry_form_cat_publish {
  font-size: 13px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entry_form_cat_publish div {
  width: 45%;
  border: 1px solid;
  border-radius: 8px;
  padding: 0.5vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: BigCaslon;
}

.entry_form_cat_publish div:last-child {
  margin-left: 5%;
  background-color: #434343;
  color: #e9eaee;
}

input[type="file"] {
  display: none;
}

.entry_form_inner {
  /* margin-top: 1vw; */
}

.entry_form .entry_form_inner {
  display: flex;
  align-items: baseline;
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
}

.entry_form footer {
  display: flex;
  align-items: flex-start;
  margin-top: 2vw;
}

.entry_form footer p:first-child {
  font-weight: 800;
  margin-top: 0;
}

.entry_form footer p {
  margin: 0;
  font-size: 11px;
  font-family: FrutigerLTStd-Light;
  color: #434343;
  margin-top: 10px;
  font-weight: 800;
}


.entry_form_left_container {
  width: 45%;
  /* to optimize */
  margin-right: 50px;
}

.entry_form_center_container {
  width: 25%;
}

.entry_form_center_container label {
  padding: 3px;
  border: 1px solid;
  border-radius: 6px;
  cursor: pointer;
}

.entry_form_upload_instructions {
  margin-top: 1vw;
}

.entry_form_right_container {
  width: 30%;
}

.entry_form_center_container {
  margin-right: 3vw;
}

.entry_form_left_container input,
.entry_form_left_container textarea {
  width: 92%;
}

.entry_form_right_keywords {
  margin-top: 1vw;
}

.entry_form_right_keywords div {
  display: inline-block;
  padding: 3px;
  margin: 2px;
  border: 1px solid #434343;
  color: #434343;
  border-radius: 8px;
  cursor: pointer;
  background-color: #e9eaf5;
}

.entry_form_right_keywords div span {
  font-size: 12px;
}

.entry_form_left_container textarea {
  font-family: FrutigerLTStd-Light;
  margin-top: 1vw;
  padding: 10px;
  height: 33vh;
  border: 1px solid #434343;
  background-color: transparent;
  overflow: auto;
  outline: none;
  resize: none; /*remove the resize handle on the bottom right*/
}

.index_title_keywords{
  display: block !important;
}

.index_title_keywords span{
  font-weight: bolder;
}


.credits_text_container p:nth-child(12),
.credits_text_container p:nth-child(13){
  margin-bottom: 1vw;
}

.title_keywords_close_div,
.title_keywords_close_div span{
  width: 100% !important;
  margin-left: 0 !important;
  display: block !important;
}

.filters {
  position: fixed;
  left: 0;
  top: 0;
  width: 41vw;
  height: 97vh;
  border: 1px solid #434343;
  margin: 10px;
  border-radius: 20px;
  z-index: 10;
}

.filters_inner {
  left: 11vw;
  position: absolute;
  top: 10vh;
  display: flex;
  height: 80%;
}

.filter_title {
  width: fit-content;
  text-transform: uppercase;
  margin-left: 0.4vw;
}

.filters_list {
  margin-top: 3vw;
  overflow: scroll;
  margin-right: 1.5vw;
  height: 80%;
}

.filters_list span {
  margin: 0.1vw;
  padding: 0.2vw;
  border-radius: 9px;
  font-family: BigCaslon;
}

.filters_list span,
.filters_cta {
  cursor: pointer;
  font-size: 14px;
}

.filters_cta{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'FrutigerLTStd-Light';
  margin-right: 1vw;
}

.filters_inner div span {
  display: block;
  width: fit-content;
  font-size: 14px;
  font-family: BigCaslon;
}

.filters * {
  color: #434343;
}

.filter_delete_selection_outer {
  margin-bottom: 1vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.filters_show_random{
  display: none;
}

.filters_delete_selection {
  margin-top: 1vw;
}

.filters_delete_selection,
.filters_launch_selection,
.filters_show_random {
  border: 1px solid;
  padding: 0.2vw;
  border-radius: 9px;
  text-transform: uppercase;
}

.entry_content {
  border: 1px solid #434343;
  width: 650px;
  z-index: 1;
  background-color: #e9eaee;
  padding: 1vw;
  margin-left: 1vw;
  z-index: 100;
  position: absolute;
}


.missing_info_background{
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.missing_info_popup_main{
  z-index: 10000;
  left: 14vw;
  top: 4vw;
  position: absolute;
  background-color: rgb(177, 101, 101);
  padding: 1vw;
  border-radius: 10px;
}


.missing_info_popup_main span{
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  text-transform: uppercase;
  color: rgb(233, 234, 238);
}

.cookies_section{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 3vw;
}

.cookies_section img {
  width: 38vw;
  margin-bottom: -3vw;
  z-index: 1000000;
  position: relative;
}

.cookies_div{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000000000;
}

.cookies_section span{
  margin: 2vw;
  cursor: pointer;
  font-family: "BigCaslon";
  border: 1px solid #434343;
  border-radius: 10px;
  padding: 0.5vw;
  font-family: BigCaslon;
  cursor: pointer;
  margin-bottom: 0.5vw;
}

.cookies_section span:first-child{
  background-color:  #434343;
  color: #e9eaee;
}



.close_cross {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.close_cross:hover {
  opacity: 1;
}

.close_cross:before,
.close_cross:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 25px;
  width: 1px;
  background-color: #333;
}

.close_cross:before {
  transform: rotate(45deg);
}

.close_cross:after {
  transform: rotate(-45deg);
}

.filters_close {
  position: absolute;
  right: 2vw;
  top: 2vw;
}

.list_close {
  position: absolute;
  right: 2vw;
  top: 1vw;
}

/* this should be in the other */
.entry_content_blocker {
  position: fixed;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}




.base_entry_body_container {
  height: 160px;
  font-family: FrutigerLTStd-Light;
  font-weight: 100;
  display: block;
  font-size: 1vw;
  margin-top: 0.5vw;
}

.is_only div {
  overflow: scroll;
  height: 100%;
  padding-right: 1vw;
  font-size: 15px;
  white-space: pre-wrap;
}

.text_media {
  display: flex;
  justify-content: center;
}

.text_media div:first-child {
  overflow: scroll;
  padding-right: 1vw;
  white-space: pre-wrap;
}

.text_media_divs {
  width: 50%;
}

.base_entry_img {
  border-radius: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media_img_media_audio img{
  height: 11vh !important;
}

.text_media div:last-child img {
  mix-blend-mode: screen;
  filter: grayscale(100%) contrast(100%);
  opacity: 1;
  display: block;
  max-width: 100%;
  border-radius: 5px;
  height: 100%;
}

.entry_content h1 {
  font-family: BigCaslon;
  margin: 0;
  font-weight: 100;
  font-size: 2vw;
  text-transform: uppercase;
}

.entry_content h2 {
  font-family: FrutigerLTStd-Light;
  font-size: 13px;
  font-weight: 800;
  margin-bottom: 1vw;
}

.entry_content span {
  font-family: FrutigerLTStd-Light;
  display: block;
  font-weight: 100;
  font-size: 13px;
  text-transform: uppercase;
}

/* map section */
.base_entry_container {
  position: absolute;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.base_entry_container img{
  width: 13vw;
}

.base_entry_cells {
  animation-iteration-count: infinite;
  margin: 5px;
}

.menu {
  position: fixed;
  left: 2.7vw;
  top: 42%;
  z-index: 10;
}

.logo_entry_outer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_entry {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #434343;
  padding: 1vw;
  border-radius: 20px;
  padding-left: 6vw;
  padding-right: 6vw;
}

.logo_entry h1 {
  font-weight: 100;
  margin: 0;
}

.logo_entry_outer .logo_entry h1 {
  font-size: 4.5vw;
  color: #434343;
}

.logo_entry_outer .logo_entry span {
  font-size: 1.8vw;
}

.logo_small_outer{
  position: fixed;
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-top: 3vw;
  z-index: 1;
}

.logo_small {
  cursor: pointer;
  top: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #434343;
  padding: 10px;
  padding-bottom: 7px;
  border-radius: 12px;
  z-index: -1;
}

.logo_small h1 {
  font-weight: 100;
  margin: 0;
  color: #434343;
}

.logo_small span {
  font-size: 13px;
  color: #434343;
}

.menu,
.logo_small,
.logo_entry {
  font-family: BigCaslon;
  font-weight: lighter;
}



.menu span {
  display: block;
  margin-bottom: 1.2vw;
  cursor: pointer;
  font-size: 15px;
  color: #434343;
}

.filter_random_cta {
  left: 11vw;
  position: absolute;
  margin-bottom: 1vw;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.filter_random_cta span {
  cursor: pointer;
  font-size: 12px;
}

.cells_inner {
  position: absolute;
  border-radius: 120px;
  background-color: black;
  filter: blur(13px);
}

.info_background {
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  /* display: flex; */
  width: 100vw;
  height: 100vh;
  z-index: -1;
}


.close_ballados{
  position: absolute;
  right: 11vw;
  top: 7vw;
}

.ballado_background {
  cursor: pointer;
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  /* display: flex; */
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.contact_section{
  position: relative;
}

.contact_section img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  display: none;
}

.info_section nav{
  position: fixed;
  bottom: 19vw;
  width: 2vw;
  z-index: 100;
  right: 21vw;
}

.info_section nav img{
  width: 100%;
}

/* info section */
.info_section,
.contact_section {
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  z-index: 2;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ethical_popup{
  margin-top: 2vw;
  margin-bottom: 1vw;
  font-family: FrutigerLTStd-Light;
  font-size: 15px;
  width: 50vw;
}

.info_section section,
.contact_section section {
  width: 60vw;
  height: 40vh;
  border: 1px solid #434343;
  background-color: #e9eaee;
  border-radius: 10px;
  padding: 2vw;
}

.second_info_div .bold{
  margin-bottom: 1vw;
}

.info_section section {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  overflow: scroll;
  position: absolute;
}

.info_shape_bottom{
  position: absolute;
  width: 17%;
  margin-top: 35vw;
}

.know_more_cookies_section{
  bottom: 29px;
  width: 21vw;
  background-color: rgb(233, 234, 238);
  border-radius: 20px;
  padding: 1vw;
  border: 1px solid #434343;
  z-index: 10000000000;
  position: fixed;
}

.cookies_background_blocker{
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.know_more_cookies_section_cta p{
  cursor: pointer;
}

.know_more_cookies_section p {
  font-family: BigCaslon;
  font-weight: lighter;
}


.info_section .info_section_add {
  cursor: pointer;
  padding: 0;
  width: 100%;
  margin-left: 8vw;
  position: fixed;
  bottom: 7vw;
}

.info_section .info_section_add img {
  width: 12vw;
}

.info_section section div {
  padding: 2vw;
  font-family: FrutigerLTStd-Light;
  line-height: 22px;
  margin: 0;
}

.info_section_bloc_left {
  width: 60%;
}

.info_section_bloc_right {
  width: 40%;
  position: relative;
}

.sponsors_logo_main{
  display: flex;
  align-items: center;
}

.project_logos{
  width: 33.3%;
  margin: 0;
  display: inline-block;
}

.project_logos img{
  width: 100%;
}

.first_info{
  display: flex;
}

.info_section_bloc_right img {
  position: fixed;
  top: 17vw;
  right: 19vw;
  width: 150px;
}

.info_section section div p {
  font-family: FrutigerLTStd-Light;
  line-height: 22px;
  margin: 0;
}


.map_add_cta {
  position: fixed;
  bottom: 3vw;
  right: 3vw;
  z-index: 1;
  width: 13vw;
  cursor: pointer;
}

.map_delete_filters_cta {
  position: fixed;
  bottom: 3vw;
  left: 3vw;
  z-index: 1;
  width: 11vw;
  transform: rotate(3deg);
  cursor: pointer;
}

.contact_section section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.contact_section_second_div{
  flex-direction: column;
}

.contact_section img:first-child {
  position: absolute;
  width: 100px;
  left: 2vw;
  top: 2vw;
}

.contact_section section div {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.contact_section section div * {
  text-align: left;
  font-family: "FrutigerLTStd-Light";
}

.contact_section_first_div,
.contact_section_second_div{
  width: 50%;
}


.contact_section_first_div{
  margin-left: 2vw;
  margin-right: 2vw;
}

.contact_section section div a {
  font-family: "FrutigerLTStd-Light";
  font-weight: 800;
  color: inherit;
  text-decoration: underline;
  letter-spacing: 1px;
}

.bold{
  font-weight: 800;
}


 /* mobile entry page */
@media screen and (max-width: 415px) {

  .cookies_section img{
    width: 100%;
    }

  .cookies_section{
    margin: 0;
    width: 100vw;
    margin-bottom: 6vw;
  }

  .know_more_cookies_section{
    bottom: 10px;
    width: 98vw;
    height: 70vh;
  }

  .first_info {
    display: flex;
    flex-direction: column;
  }

  

  .info_section section{
    width: 68vw;
    margin-left: 20vw;
    top: 50vw;
  }


  .list_index_main{
    margin-top: 40vw;
    margin-bottom: 40vw;
  }

  .index_section_list_cells_open_inner_1{
    width: 80%;
  }

  #menu_span_3{
    display: none;
  }


/* 
  .ballado_img_block label{
    font-size: 7px;
  }

  .ballado_main{
    z-index: 100;
  }

  .ballado_left h2{
    font-size: 10px;
  }

  .ballado_left p{
    font-size: 10px;
  }

  .ballado_left{
    overflow-x: hidden;
  } */

  .index_section_list_cells_open{
    font-size: 11px;
    flex-direction: column;
  }

  .index_section_list_cells_open_inner_2{
    width: 80%;
  }

  .index_section_list_cells{
    flex-direction: column;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }

  .index_section .index_section_main header{
    flex-direction: column;
  }

  .index_section .index_section_main header span, .index_section_list_cells div{
    width: 80%;
    font-size: 12px;
  }

  .menu span{
    font-size: 12px;
  }

  .info_section_bloc_left,
  .info_section_bloc_right{
    width: 100%;
  }

  .sponsors_logo_main{
    flex-direction: column;
  }

  .project_logos{
    width: 60%;
  }


  .en_fr_cta_entry {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 7.5vh;
  }

  .logo_entry{
    width: 70%;
  }

  .logo_entry_outer .logo_entry h1{
    font-size: 8vw;
  }

  .logo_entry_outer .logo_entry span{
    font-size: 4vw;
    text-align: center;
    width: 70%;
  }

  .entry_acceptance_popup{
    width: 71vw;
    display: flex;
    flex-direction: column;
  }

  .en_fr_cta_entry span {
    border: 1px solid #434343;
    border-radius: 10px;
    padding: 2vw 6vw;
    margin: 2vw;
    font-size: 5vw;
    font-family: BigCaslon;
    cursor: pointer;
  }

  .en_fr_cta_entry span:first-child {
    margin-right: 2vw;
  }

  .en_fr_cta_entry span:last-child {
    margin-left: 2vw;
  }
  
  .entry_text section {
    width: 78%;
    height: 60%;
    padding: 4vw;
  }

  .entry_text_cta_section:last-child{
    display: none;
  }


  .entry_text_header {
    text-align: center;
    margin: 4vw;
  }
  .entry_text section h1 {
    font-size: 8vw;
    margin: 0;
  }
  .entry_text_first_header .entry_text_header span {
    font-size: 3.3vw;
    width: 52%;
  }
  .entry_text .entry_text_first_section {
    position: fixed;
    top: 6vw
  }
  .entry_text_cta_section span {
    border: 1px solid #434343;
    color: white;
    background:  #434343;
    border-radius: 10px;
    padding: 2vw 4vw;
    margin: 2vw;
    font-size: 12px;
    font-family: BigCaslon;
    cursor: pointer;
  }
  .entry_text_desc {
    flex-direction: column;
    overflow: scroll;
  }
  .entry_text section .entry_text_desc div {
    width: 100%;
    margin: 0;
    font-size: 6vw;
  }
  .entry_text section .entry_text_desc p {
    margin: 2vw;
    font-size: 13px;
  }

  .entry_text_ctas{
    flex-direction: column;
  }

  .entry_text_cta_section{
    width: 100%;
  }

  .cookies_section span{
    font-size: 15px;
  }

  .entry_acceptance_popup{
    width: 71vw;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 6vw;
  }

  .map_add_cta{
    display: none;
  }

  .base_entry_container img{
    width: 30vw;
  }

  .entry_content_hover{
    display: none;
  }

  .text_media div:last-child img{
    height: auto;
  }

  .en_fr_cta_map{
    bottom: 3.5vw;
    top: initial;
  }

  .info_section_add{
    display: none;
  }

  .info_section section div p{
    font-size: 12px;
  }


  .filters{
    width: 100vw;
    height: 70vh;
    background-color: rgb(233, 234, 238);
    z-index: 100;
    margin: 0;
  }

  .filters_inner div span{
    font-size: 10px;
  }

  .filter_random_cta span{
    padding: 1vw;
  }


  .entry_content h1{
    font-size: 16px;
  }

  .is_only div,
  .text_media_divs{
    font-size: 10px;
  }


  .entry_content h2{
    font-size: 10px;
    margin-bottom: 2vw;
  }

  .entry_content span{
    font-size: 10px;
  }

  .entry_content{
    width: 200px;
  }

  .text_media{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .text_media_divs{
    width: auto;
  }

  .contact_section section{
    flex-direction: column;
    overflow: scroll;
    justify-content: initial;
    margin-left: 20vw;
  }

  .contact_section section div *{
    font-size: 12px;
  }

  .cookies_background_blocker{
    background-color: rgb(233, 234, 238);
  }


}